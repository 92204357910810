<template>
    <div class="jsmyinfo">
        <div class="jsmyinfo_content">
            <!--            头部信息-->
            <div class="jsmyinfo_header">
                <div class="jsmyinfo_header_icon"><img :src="avatar"/></div>
                <div class="jsmyinfo_header_info">
                    <div class="nickname">{{nickName}}</div>
                    <div class="p">
                        <img src="assets/company_tips.png"/>
                        <div class="p1">公司</div>
                    </div>
                </div>
            </div>
            <!--填写信息1-->
            <div class="jsmyinfo_input">
                <div class="p">企业信息</div>
                <div class="item">
                    <div>企业名称<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" v-model="name" placeholder="填写企业名称" />
                    </div>
                </div>

                <div class="item">
                    <div>企业地址<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" v-model="address" placeholder="填写企业地址" />
                    </div>
                </div>

                <div class="item">
                    <div>联系电话<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" v-model="phone" placeholder="填写联系电话" />
                    </div>
                </div>

                <div class="item">
                    <div>企业税号<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" v-model="tax_number" placeholder="填写企业税号" />
                    </div>
                </div>

                <div class="item">
                    <div>行业类型<span style="color: red">*</span></div>
                    <div class="input select_time">
                        <img src="assets/ChevronRight.png" class="down"/>
                        <input type="text" readonly @click="popupVisible=true" :value="categoryV" placeholder="--请选择行业类型--" />
                    </div>
                </div>

                <div class="item">
                    <div>企业性质<span style="color: red">*</span></div>
                    <div class="input select_time">
                        <img src="assets/ChevronRight.png" class="down"/>
                        <input type="text" readonly @click="popupNatureVisible=true" :value="natureV" placeholder="--请选择企业性质--" />
                    </div>
                </div>

                <div class="item">
                    <div>企业产品<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" v-model="produce" placeholder="填写企业产品" />
                    </div>
                </div>

                <div class="item">
                    <div>经营范围<span style="color: red">*</span></div>
                    <div class="input">
                        <input type="text" v-model="business_scope" placeholder="填写经营范围" />
                    </div>
                </div>

            </div>
        </div>

        <!--按钮-->
        <div class="jsmyinfo_tools">
<!--            <div class="btnback">返回</div>-->
            <div class="btnconfirm" @click="btnUpdate">确认</div>
        </div>

<!--        行业类型-->
        <Popup class="mint-popup dtype_mint-popup"
               v-model="popupVisible"
               position="bottom">
            <Picker
                    :slots="categoryType"
                    :visible-item-count="3"
                    :show-toolbar="true"
                    ref="catapicker"
                    value-key="v"
                    class="ownpicker">
                <span @click="handeConfirmCatagoryType" class="btn-sure">确定</span>
            </Picker>
        </Popup>

<!--        企业性质-->
        <Popup class="mint-popup dtype_mint-popup"
               v-model="popupNatureVisible"
               position="bottom">
            <Picker
                    :slots="natureType"
                    :visible-item-count="3"
                    :show-toolbar="true"
                    ref="naturepicker"
                    value-key="v" class="ownpicker">
                <span @click="handeConfirmNatureType" class="btn-sure">确定</span>
            </Picker>
        </Popup>

    </div>
</template>

<script>
    import keys from "../../xyg/utils/keys";
    import {Picker,Popup,Toast} from 'mint-ui';

    export default {
        name: "GsMyInfo",
        components:{
            Picker,
            Popup
        },
        data(){
            return {
                nickName:'',
                avatar:'',

                popupVisible:false,
                popupNatureVisible:false,
                name:'',
                address:'',
                phone:'',
                tax_number:'',
                categoryV:'',
                categoryId:0,
                categoryType:[
                    {
                        flex: 1,
                        values: [{v:"加工生产型",key:1},{v:"贸易代理型",key:2}],
                        className: 'slot1',
                        textAlign: 'center'
                    }
                ],
                natureV:'',
                natureId:0,
                natureType:[
                    {
                        flex: 1,
                        values: [
                            {v:"国有企业",key:1},
                            {v:"股份制企业",key:2},
                            {v:"私营企业",key:3},
                            {v:"外商投资企业",key:4},
                            {v:"港澳台企业",key:5}],
                        className: 'slot1',
                        textAlign: 'center'
                    }
                ],
                produce:'',
                business_scope:''

            }
        },
        mounted(){
            this.init();
        },
        methods:{
            init(){
                let openId=localStorage.getItem(keys.openId);
                this.$post('/api/auth/user/enterprise/detail',{openId:openId})
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){
                            this.name=rsp.data.name;
                            this.address=rsp.data.address;
                            this.phone=rsp.data.phone;
                            this.tax_number=rsp.data.taxNumber;
                            this.produce=rsp.data.produce;
                            this.business_scope=rsp.data.businessScope;
                            this.nickName=rsp.data.nickName;
                            this.avatar=rsp.data.avatar;
                            this.natureId=rsp.data.nature;
                            this.natureV=this.initNatureV();

                            this.categoryId=rsp.data.category;
                            this.categoryV=this.initCataV();
                            //     name:this.name,
                            //     address:this.address,
                            //     phone:this.phone,
                            //     tax_number:this.tax_number,
                            //     category:this.categoryId,
                            //     nature:this.natureId,
                            //     produce:this.produce,
                            //     business_scope:this.business_scope
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            initNatureV(){
                if(this.natureId==1) return "国有企业";
                if(this.natureId==2) return "股份制企业";
                if(this.natureId==3) return "私营企业";
                if(this.natureId==4) return "外商投资企业";
                if(this.natureId==5) return "港澳台企业";
                return "";
            },
            initCataV(){
                if(this.categoryId==1) return "加工生产型";
                if(this.categoryId==2) return "贸易代理型";
                return "";
            },
            handeConfirmCatagoryType(){
                let selected=this.$refs.catapicker.getValues()[0];
                let selected_v=selected.v;
                let selected_id=selected.key;
                console.log(selected);
                this.categoryV=selected_v;
                this.categoryId=selected_id;
                this.popupVisible=false;
            },
            handeConfirmNatureType(){
                let selected=this.$refs.naturepicker.getValues()[0];
                let selected_v=selected.v;
                let selected_id=selected.key;
                console.log(selected);
                this.natureV=selected_v;
                this.natureId=selected_id;
                this.popupNatureVisible=false;
            },
            btnUpdate(){
                let openId=localStorage.getItem(keys.openId);
                let validPhoneOk=this.validPhone();
                if(!validPhoneOk[0]){
                    Toast({
                        message: validPhoneOk[1],
                        position: 'bottom',
                        duration: 1500
                    });
                    return;
                }
                let data={
                    openId:openId,
                    name:this.name,
                    address:this.address,
                    phone:this.phone,
                    taxNumber:this.tax_number,
                    category:this.categoryId,
                    nature:this.natureId,
                    produce:this.produce,
                    businessScope:this.business_scope
                };
                console.log(data);
                this.$post('/api/auth/user/enterprise/update',data)
                    .then(rsp=>{
                        if(rsp.code==200 && rsp.success){

                            Toast({
                                message: '提交成功',
                                position: 'bottom',
                                duration: 1500
                            });

                            //关闭窗口
                            this.closeDraw();
                        }
                    })
                    .catch(e=>{
                        console.log(e);
                    })
            },
            validPhone(){
                var boo,msg;
                if(!(/^[0-9]*$/.test(this.phone))){
                    return [boo=false,msg="电话号码错误"];
                }
                return [boo=true,msg="手机号正确"];
            }
        }
    }
</script>

<style scoped>
    .jsmyinfo{
        width: 750px;
    }

    .jsmyinfo .jsmyinfo_content{
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        background-color: white;
        border-radius: 10px;
        /*border-top-left-radius: 10px;*/
        /*border-top-right-radius: 10px;*/
        /*border-bottom-left-radius: 10px;*/
        /*border-bottom-right-radius: 10px;*/
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_icon{
        width: 100px;
        height: 100px;
        margin-left: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_icon img{
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info{
        display: flex;
        flex-direction: column;
        margin-left: 24px;
        letter-spacing: 2px;

    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info .nickname{
        font-size: 26px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info .p{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 22px;
        color: #A79F9F;
        margin-left: 4px;
        margin-top: 16px;
    }
    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info .p1{
        margin-left: 8px;
    }

    .jsmyinfo .jsmyinfo_content .jsmyinfo_header .jsmyinfo_header_info img{
        width: 14px;
        height: 18px;
    }

    .jsmyinfo .jsmyinfo_input{
        margin-top: 2px;
        background-color: white;
        border-top: 2px #E7EAF1 solid;
        letter-spacing: 2px;
    }

    .jsmyinfo .bottom_radius{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .jsmyinfo .jsmyinfo_input .p{
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 40px;
        font-weight: bold;
        font-size: 28px;
    }

    .jsmyinfo .jsmyinfo_input .item{
        margin-left: 40px;
        margin-right: 40px;
        height: 120px;
        font-size: 26px;

        display: flex;
        flex-direction: row;
        align-items: center;


        margin-bottom: 4px;
        border-bottom: 4px #FCFCFC solid;
    }

    .jsmyinfo .jsmyinfo_input .item .input{
        margin-left: 50px;
    }

    .jsmyinfo .jsmyinfo_input .item .input input[type='text']{
        border-width:0px;
        height: 40px;
        border: none;
        outline: medium;
        padding:0;
    }

    .jsmyinfo .jsmyinfo_tools{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .jsmyinfo .jsmyinfo_tools .btnback{
        background-color: #D1CECE;
        border-radius: 10px;
        color: #7A7777;
        font-size: 28px;
        letter-spacing: 4px;
        width: 200px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .jsmyinfo .jsmyinfo_tools .btnconfirm{
        background-color: #226DF8;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 28px;
        letter-spacing: 4px;
        width: 200px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .dtype_mint-popup{
        width: 750px;
    }

    .btn-sure{

    }
    .ownpicker >>> .picker-toolbar{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-right: 20px;
        color:#226DF8;
    }
    .select_time{
        margin-left: unset !important;
    }

    .select_time .down{
        width: 20px !important;
        height: 12px !important;
        margin-left: 50px;
        margin-right: 20px;
    }
</style>
